<template>
  <div class="operation-logs">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :pageTitle="$t('log.operation-log')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #operator="scope"
        ><a class="user-link" @click="viewUser(scope.row.id)">{{
          scope.row.operator
        }}</a></template
      >
      <template #module="scope">{{ $t(scope.row.module) }}</template>
      <template #operation="scope">{{ $t(scope.row.operation) }}</template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { useRouter } from 'vue-router'
import { searchFormConfig } from './config/operation.form'
import { contentTableConfig } from './config/operation.table'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()

const pageContentRef = ref('')
const pageSearchRef = ref('')

const userInfo = store.state.userInfo || {}

const pageInitialQuery = {
  operator: userInfo.userName
}

const tableOptions = {
  moduleName: 'log', // 所属的模块
  pageName: 'operation', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  pageAction: 'log/getOperationListData', // 获取表格的Action// 删除record的Action
  pageCountGetter: 'log/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'log/pageListData',
  // isShowDeleteSlot: true,
  pageInitialQuery,
  deleteSelectionAction: 'log/deleteMultiOperation'
  // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)
const searchFormConfigRef = computed(() => {
  return searchFormConfig
})

const handleQueryClick = (queryInfo) => {
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData(pageInitialQuery)
}
const viewUser = (id) => {
  router.push(`/users/view/${id}`)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
<style lang="scss">
.operation-logs {
  .user-link {
    color: #1e66a3;
  }
}
</style>
