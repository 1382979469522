import { getGlobalParamsOptionsAsync } from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'module',
      type: 'select',
      options: [],
      label: 'log.module',
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('moduleList', {
          isNeedPreFix: false,
          valueParams: 'value',
          labelParams2: 'label'
        })
    },
    {
      field: 'createdBy',
      type: 'select',
      label: 'general.operator',
      options: [],
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('userList', {
          isNeedPreFix: false,
          valueParams: 'userName',
          labelParams2: 'userName'
        })
    },
    {
      field: 'operationType',
      type: 'select',
      label: 'log.operation-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('operationTypeList', {
          isNeedPreFix: false,
          valueParams: 'value',
          labelParams2: 'value'
        })
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.operation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
